import { useMeStore } from '~/modules/SDK/me/useMeStore';
/**
 * 建立權限驗證邏輯器
 *
 * @example <caption>驗證是否有 web@{agentName} 權限</caption>
 *   // meCheckHandlerAgentWeb.tsx
 *   export const meCheckHandlerAgentWeb = meCreateCheckHandler(scope => {
 *     const subscriptions = scope.meUserState?.subscriptions?.find(item => {
 *       const agent = item.agentName
 *       const product = item.product
 *       return agent === scope.agentName && product === 'web'
 *     })
 *
 *     return !!subscriptions
 *   })
 *
 *   // pages/index.tsx
 *   const TestComponent: React.FC = props => {
 *     const hasAgentSubscription = meCheckHandlerAgentWeb.check()
 *
 *     return <div>{hasAgentSubscription}</div>
 *   }
 */
export const meCreateCheckHandler = (handler) => {
    function meCheckHandler() {
        const state = useMeStore.getState();
        return handler({
            agentName: state.agentName,
            meFirebaseState: state.meFirebaseState,
            meUserState: state.meUserState,
            isValidTrialUser: state.isValidTrialUser,
        });
    }
    meCheckHandler.check = meCheckHandler;
    meCheckHandler.useCheck = function useCheck() {
        useMeStore(state => state.agentName);
        useMeStore(state => state.product);
        useMeStore(state => state.meFirebaseState);
        useMeStore(state => state.meUserState);
        useMeStore(state => state.isValidTrialUser);
        return meCheckHandler();
    };
    return meCheckHandler;
};
