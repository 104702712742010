import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
export class TvAPIs extends Urlu {
    wsUrl = '';
    get request() {
        return super.request.withAgent.withProduct;
    }
    kbars = new Urlu.Query((params) => {
        return this.request.axios
            .get(`/history`, {
            params: {
                ws: true,
                ...params,
                from: params.from?.unix(),
                to: params.to?.unix(),
                intraday: params.intraday,
                //
                from_DEBUG_: params.from?.format('YYYY-MM-DD HH:mm:ss'),
                to_DEBUG_: params.to?.format('YYYY-MM-DD HH:mm:ss'),
            },
        })
            .then(res => res.data);
    });
    /**
     * # @deprecated
     *
     * ## 改用 TvAPIs.kbars
     *
     * @deprecated
     */
    async fetchKbars(params) {
        const kbars = await this.request.axios
            .get(`/history`, {
            params: {
                ws: true,
                ...params,
                from: params.from.unix(),
                to: params.to.unix(),
                //
                from_DEBUG_: params.from.format('YYYY-MM-DD HH:mm:ss'),
                to_DEBUG_: params.to.format('YYYY-MM-DD HH:mm:ss'),
            },
        })
            .then(res => res.data);
        return kbars;
    }
    async fetchAll() {
        const staticJsonOfAll = await this.request.withAgent.axios
            .get(`/search?query=&exchange=&type=all_types`)
            .then(res => res.data);
        return staticJsonOfAll;
    }
    /**
     * 返回 e.g. `1678575186
     *
     * @example
     *   //
     *   // 返回之後的用法
     *   const serverTime = dayAPI(1678575186 * 1000).format()
     *
     *   serverTime.unix() // 1678575186
     */
    fetchServerTime = async () => {
        return await this.request.axios.get(`/time`).then(res => res.data);
    };
    fetchConfig = async () => {
        return await this.request.axios
            .get(`/config`)
            .then(res => res.data);
    };
    search = async (params) => {
        return await this.request.axios
            .get(`/search`, { params })
            .then(res => res.data);
    };
    resolveSymbol = async (params) => {
        return await this.request.axios.get(`/symbols`, { params }).then(res => res.data);
    };
    fetchHistory = async (params) => {
        return await this.request.axios
            .get(`/history`, { params })
            .then(res => res.data);
    };
}
