export var SignalrEvent;
(function (SignalrEvent) {
    /** # OHLC 報價 */
    SignalrEvent["Quote"] = "quote";
    SignalrEvent["GetSymbols"] = "GetSymbols";
    /** # 市場掛單 */
    SignalrEvent["TradeInfo"] = "TradeInfo";
    /** # 五檔掛單簿 */
    SignalrEvent["BidAsk"] = "BidAsk";
    /** # 逐筆成交 */
    SignalrEvent["Tick"] = "Tick";
})(SignalrEvent || (SignalrEvent = {}));
