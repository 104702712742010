import { css } from '@emotion/react';
// Thin (Hairline)
export const fontWeight100 = css `
  font-weight: 100;
`;
// Extra Light (Ultra Light)
export const fontWeight200 = css `
  font-weight: 200;
`;
// Normal (Regular)
export const fontWeight400 = css `
  font-weight: 400;
`;
// Medium
export const fontWeight500 = css `
  font-weight: 500;
`;
// Semi Bold (Demi Bold)
export const fontWeight600 = css `
  font-weight: 600;
`;
