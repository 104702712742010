import { createContainer as createContainerOrigin } from 'unstated-next';
import { expectType } from '~/utils/tsd';
import { __TEST__ } from '~/utils/__TEST__';
/**
 * ## 🟢 Create a UnstatedState Container which has additional features
 *
 * > This to instead of "createContainer" of "unstated-next" itself
 *
 * 1. has auto-assigned "displayName"
 *
 * > Based on the "createContainer" of unstated-next original, this util function to be make sure
 * the "Provider" to has the "displayName" according to the name of given function of "hook"
 *
 * 1. "useState", a replacement for "useContainer" with optional "Provider" support
 *
 * > Get rid of the error throw "You have to wrapper with Provider", useful for communication of
 * containers between multi-modules
 *
 * @example
 *   // ~/hooks/useXxxYyyState
 *
 *   export const useXxxYyyState = createContainer(() => {
 *     return {}
 *   })
 */
export const createContainer = (reactHook) => {
    const container = createContainerOrigin(reactHook);
    if (!reactHook.name) {
        console.warn(`${createContainer.name} Warn: 你傳入的 React Hook 沒有名字，將造成 React devtool 失去 Provider 名稱標示，將箭頭函式轉換成 function useHook() 以解決此問題。`);
    }
    container.Provider.displayName = `Provider:${reactHook.name}`;
    let state = null;
    container.useState = () => {
        try {
            state = container.useContainer();
        }
        catch (error) {
            state = null;
        }
        return state;
    };
    return container;
};
/* istanbul ignore next */
if (__TEST__) {
    /* eslint-disable:start no-inner-declarations */
    const useMyAuth = (a, b, cd) => {
        const fn1 = (c) => a;
        return { act: { fn1 } };
    };
    const myAuthContainer = createContainer(useMyAuth);
    /* eslint-disable no-inner-declarations */
    function TypesTesting() {
        const contn = myAuthContainer.useContainer();
        // contn.act NOT to be Any
        expectType(false);
        expectType(contn.act);
        expectType(contn.act.fn1);
        expectType([123, '123', { d: { bo: true } }]);
        expectType('123');
        /** @ts-expect-error 預期 displayName 應是 string 不會有 undefined */
        expectType(undefined);
        return (<myAuthContainer.Provider>
        <span>hello world</span>
      </myAuthContainer.Provider>);
    }
    /*  eslint-enable no-inner-declarations */
}
