/**
 * # @deprecated 請見 {@link EventWhyThisDeprecated}
 *
 * @deprecated
 */
export var EventString;
(function (EventString) {
    /** Signalr Socket 連接時 */
    EventString["signalrOnConnect"] = "signalrOnConnect";
    /** Signalr 開始訂閱商品 */
    EventString["signalrSubscribeAdd"] = "signalrSubscribeAdd";
    /** 電腦或手機休眠時 */
    EventString["visibilityChange"] = "visibilityChange";
    /** 當 Chart 準備好時（異步） */
    EventString["onTVChartReady"] = "onTVChartReady";
    /** 每當 ChartSymbol 改變時（異步） */
    EventString["onTVChartSymbolChanged"] = "onTVChartSymbolChanged";
    /** 每當 ChartInterval 改變時（異步） */
    EventString["onTVChartIntervalChanged"] = "onTVChartIntervalChanged";
})(EventString || (EventString = {}));
