import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
export class StrategyDevAPI extends Urlu {
    /** 這邊專用的 jwt */
    get jwt() {
        return this._jwt;
    }
    strategy = this.createQuery('get', '/api/strategies/{strategyId}');
    login = this.createQuery('post', '/api/users/login');
    me = this.createQuery('get', '/api/users/{account}');
    searchStrategy = this.createQuery('get', '/api/strategies');
    putStrategy = this.createQuery('put', '/api/strategies/{strategyId}');
    setJwt(jwt) {
        this._jwt = jwt;
    }
    _jwt = '';
}
