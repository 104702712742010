import useSWR from 'swr-0-5-6';
import { useCommonSymbolListStore, } from '~/modules/SDK/Symbol/useCommonSymbolListStore';
/**
 * # 計畫以 {@link staticStore} 替代
 *
 * 常見的、常用的報價
 *
 * 列表資料源用到的頁面
 *
 * 權值股上市 tw50: monitors/stock, ranking
 *
 * 權值股上市 OTC50: monitors/stock
 *
 * 海期 osFutures: monitors/os-futures
 *
 * 股票對應期貨 stockFutures: screener
 *
 * @deprecated
 */
export const useCommonSymbolList = () => {
    const response = useSWR('https://storage.googleapis.com/symbol-config/common_symbol_list.json', { revalidateOnFocus: false });
    if (response.data) {
        useCommonSymbolListStore.setState({
            tw50: response.data.TW50,
            otc50: response.data.OTC50,
            osfuturesList: response.data.osFuturesList,
            stockFuturesMapper: response.data.stockFutures,
            stockCategory: response.data.stockCategory,
        });
    }
};
/**
 * # 計畫以 {@link staticStore} 替代
 *
 * @deprecated
 */
export const useTw50StockList = () => {
    return useCommonSymbolListStore(s => s.tw50);
};
/**
 * # 計畫以 {@link staticStore} 替代
 *
 * @deprecated
 */
export const useOTC50StockList = () => {
    return useCommonSymbolListStore(s => s.otc50);
};
/**
 * # 計畫以 {@link staticStore} 替代
 *
 * @deprecated
 */
export const useOsFuturesList = () => {
    return useCommonSymbolListStore(s => s.osfuturesList);
};
/**
 * # 計畫以 {@link staticStore} 替代
 *
 * @deprecated
 */
export const useStockToFutureMapper = () => {
    return useCommonSymbolListStore(s => s.stockFuturesMapper);
};
