import { Dialog } from '@mui/material';
import React, { memo, useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import { authDialogState } from '~/modules/auth/containers/authDialogStore';
import { LoginForm } from '~/modules/auth/components/LoginForm';
import { useSnapshot } from 'valtio';
import { DefaultUserDetails, TrialUserDetails, UserDetails, } from '~/modules/auth/components/UserDetails';
import { meCheckHandlerTrialCode } from '~/modules/SDK/me/meCheckHandlerTrialCodeAndProductWeb';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
/**
 * 由於這個UI已經綁定已經是Root Provider等級，所以透過agentName參數去設定該顯示什麼「相關產品或使用者內容」
 */
export const LoginWithFirebaseAsDialog = memo(function AuthDialog(props) {
    const meFirebaseState = useMeStore(state => state.meFirebaseState);
    const agentName = useMeStore(state => state.agentName);
    const temporaryLegitUser = meCheckHandlerTrialCode.useCheck();
    const snap = useSnapshot(authDialogState);
    useUpdateEffect(() => {
        authDialogState.open = false;
    }, [meFirebaseState]);
    const contents = useMemo(() => {
        if (temporaryLegitUser)
            return <TrialUserDetails />;
        if (!meFirebaseState) {
            return <LoginForm />;
        }
        return agentName === 'futuresai' ? <UserDetails /> : <DefaultUserDetails />;
    }, [meFirebaseState, temporaryLegitUser, agentName]);
    return (<Dialog open={snap.open} onClose={() => {
            authDialogState.open = false;
        }}>
      {contents}
    </Dialog>);
});
