import { memo } from 'react';
import { AppTooltip } from '~/components/AppTooltip';
import { ENV } from '~/configs/ENV';
import { __PRODUCTION__ } from '~/utils/__PRODUCTION__';
export const AppVersion = memo(function AppVersion(props) {
    const _appenv = ENV;
    const commitHash = _appenv.COMMITHASH.length > 6 ? _appenv.COMMITHASH.substring(0, 4) : _appenv.COMMITHASH;
    return (<AppTooltip disableTouchListener={__PRODUCTION__} disableHoverListener={__PRODUCTION__} disableFocusListener={__PRODUCTION__} title={<span>
          {commitHash}＠{_appenv.VERSION}＠{_appenv.BRANCH}
        </span>}>
      <span data-deploy-branch={_appenv.BRANCH} className={props.className}>
        {commitHash}＠{_appenv.VERSION}
      </span>
    </AppTooltip>);
});
