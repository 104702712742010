import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
import dayAPI from '~/utils/dayAPI';
import { expectType } from '~/utils/tsd';
export class StockScreenerAPI extends Urlu {
    dailyRank = new Urlu.Query((params) => {
        return this.request.axios
            .get(`/daily_rank`, {
            params,
        })
            .then(res => res.data);
    });
    intraday = new Urlu.Query((params) => {
        return this.request.axios
            .get('/latest_trade_date', {
            params: {
                symbol: params?.symbol || '',
                intraday: true,
            },
        })
            .then(res => dayAPI(res.data.date).startOf('day'));
    });
    latestTradeDays = new Urlu.Query((params) => {
        return this.request.axios
            .get(`/latest_trade_date`, {
            params: {
                symbol: params?.symbol || '',
                size: 30,
            },
        })
            .then(res => res.data.map(datum => dayAPI(datum.date).startOf('day')));
    });
}
/* istanbul ignore next */
function TypingTesting() {
    const api = new StockScreenerAPI(``);
    api.intraday.fetch().then(foo => {
        expectType(foo);
        // @ts-expect-error 不能是 any（所以要修到沒紅線）
        expectType(foo);
    });
    const res = api.intraday.useAsync();
    expectType(res.data);
    expectType(res.error);
    // @ts-expect-error 不能是 any（所以要修到沒紅線）
    expectType(res.data);
}
