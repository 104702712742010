import { useMount } from 'react-use';
import { eventEmitter } from '~/modules/SDK/Events/eventEmitter';
import { EventString } from '~/modules/SDK/Events/EventString';
/**
 * # @deprecated 請見 {@link EventWhyThisDeprecated}
 *
 * @deprecated
 */
export const useEventsVisibleChange = () => {
    useMount(() => {
        document.addEventListener('visibilitychange', function () {
            if (document.visibilityState === 'hidden') {
                eventEmitter.emit(EventString.visibilityChange, { state: 'hidden' });
            }
            else {
                eventEmitter.emit(EventString.visibilityChange, { state: 'visible' });
            }
        });
    });
};
// export const useSocketVisibleReconnect = (
//   socketClient: MutableRefObject<Socket.Client | undefined>,
// ) => {
//   const timeHidden = useRef<Dayjs>()
//   document.addEventListener('visibilitychange', function () {
//     if (document.visibilityState === 'hidden') {
//       timeHidden.current = dayAPI()
//     } else {
//       const timeVisible = dayAPI()
//       if (timeHidden.current && timeVisible.subtract(3, 'seconds').isAfter(timeHidden.current)) {
//         socketClient.current?.disconnect()
//         setTimeout(function () {
//           socketClient.current?.connect()
//         }, reconnectAfter)
//       }
//     }
//   })
// }
