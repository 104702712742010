import { useInterval, useMount } from 'react-use';
/**
 * - 與 useInterval 同樣功能性，但會立即執行第一次，而非等待 N毫秒數之後才執行第一次
 * - 可避免 useInterval 的第一次執行的延遲問題
 *
 * @example
 *   // 飯粒
 *
 *   useIntervalNow(() => {
 *     console.log(123)
 *   }, 1000)
 */
export function useIntervalNow(callback, interval) {
    useMount(() => {
        callback();
    });
    useInterval(() => {
        callback();
    }, interval);
}
