import { flatMap, sortBy } from 'lodash';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';
import { __DEV__ } from '~/utils/__DEV__';
export const productStatusArr = ['trial', 'premium'];
export const adminStore = proxy({
    productGroup: [],
    users: [],
    admin: null,
    readonlyMode: false,
    // UI
    selectedUserId: '',
    selectedAgent: '',
    selectedProductGroup: '',
    selectedProduct: '',
    page: 0,
    totalPage: 0,
    total: 0,
    pageSize: 100,
    sortBy: undefined,
    additionSearchKey: undefined,
    sortOrder: 'asc',
    // selected: {
    //   get product(): null | Admin.Product {
    //     return adminStore
    //   },
    // },
    permissions: {
        /** TODO: 這支依賴 userScopeProduct.roleType === 'readOnly' */
        get readonlyMode() {
            return false;
        },
        get allRoles() {
            return adminStore.admin?.roles || [];
        },
        get allProductsOfRoles() {
            return flatMap(adminStore.permissions.allRoles, role => role.products);
        },
        get currentRoleType() {
            return (adminStore.permissions.allProductsOfRoles.find(product => product.name === adminStore.selectedProduct)?.roleType || 'readOnly');
        },
        get AllRolesSelectItems() {
            return adminStore.permissions.allRoles.map(role => ({
                value: role.agentName,
                label: role.agentName,
            }));
        },
    },
    get productsBySelectedAgent() {
        if (!adminStore.selectedAgent)
            return [];
        const agentRoleSelected = adminStore.admin?.roles.find(role => role.agentName === adminStore.selectedAgent);
        const pseudoProducts = adminStore.productGroup.map(pg => ({
            name: pg.productGroup,
            displayName: pg.displayName,
            id: -1,
            roleType: 'admin',
        }));
        return sortBy([...(agentRoleSelected?.products ?? []), ...pseudoProducts], ['name']);
    },
    /** 用於 `mantine.<Select data>` */
    get productsBySelectedAgentOfSelectItems() {
        return this.productsBySelectedAgent.map(product => ({
            value: product.name,
            label: product.displayName ? product.name + ' ' + product.displayName : product.name,
        }));
    },
    // additional params:
    expiredBefore: '',
    expiredAfter: '',
    subscribeAfter: '',
    subscribeBefore: '',
    createdAfter: '',
    createdBefore: '',
    kwName: '',
    kwUid: '',
    kwEmail: '',
    productStatus: 'premium',
    // modal
    modalOpen: false,
    userScopeProduct: '',
    currentUser: {},
    /** 操作記錄 */
    currentUserSubscriptionHistory: [],
});
export const adminNotifyPushStore = proxy({
    selectedUserUids: [],
    selectedProducts: [],
    pushChannel: 'Bot',
    isWebImgResource: false,
    remoteWebImgUrl: '',
    localImageFile: null,
    localImageUrl: '',
    localImageFilename: '',
    sendAll: true,
    logs: [],
    logQueryMode: '',
    logFrom: '',
    logTo: '',
    logsModalOpen: false,
    notificationTitle: '',
    notificationShow: false,
});
if (__DEV__) {
    devtools(adminStore, { name: 'dev:admin:adminStore' });
    devtools(adminNotifyPushStore, { name: 'dev:admin:adminNotifyPushStore' });
}
