import { useLocalStorage } from 'react-use';
import { useAppNamespace } from '~/hooks/useAppNamespace';
/**
 * Automatic prefixing the 「futuresai」and 「{agent}」 as the namespaces. implements based on
 * 「react-use.useLocalStorage」
 *
 * @example
 *   ## Given the key "darkmode" will store in key name "futuresai:hyt888:darkmode"
 *
 *   const [value, setValue, remove] = useAppLocalStorage('darkmode')
 */
export const useAppLocalStorage = (key, initialValue, options) => {
    const NS = useAppNamespace(key);
    return useLocalStorage(NS, initialValue, 
    /** @ts-expect-error 因為只需將汎型 V 傳入，便不影響後續類型推斷 */
    options);
};
