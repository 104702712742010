import React, { memo } from 'react';
import { LoginWithFirebaseAsDialog } from '~/modules/auth/components/LoginWithFirebaseAsDialog';
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState';
/**
 * About auth of everything should start in here of this component
 *
 * You want to display the button that user who can click to open 「dialog」 to login our site, you
 * want to display the avatar of the user who is logged in, you want to the user who is logged and
 * click the button can show the 「dialog」 of the user basic information and logout button.
 *
 * @example
 *   ;```tsx const PageContent = memo(function PageContent() {
 *            const auth = authState.useContainer()
 *
 *            return (
 *              <div>
 *                {auth.me?.name ? (
 *                  <h1>
 *                    Hi, <UserAvatarAsDialogButton />
 *                  </h1>
 *                ) : (
 *                  <h1>
 *                    Hello, please
 *                    <UserAvatarAsDialogButton loginAsText='login' />
 *                    our site.
 *                  </h1>
 *                )}
 *              </div>
 *            )
 *          })
 *
 *          const RootNextPage = memo(function RootNextPAge() {
 *            return (
 *              <AuthRootProvider>
 *                <PageContent />
 *              </AuthRootProvider>
 *            )
 *          })
 *
 *          export default RootNextPage // export default in order to serve by next.js file-system
 *         ```
 */
export const AuthRootProvider = memo(function AuthRootProvider(props) {
    return (<useFirebaseMeState.Provider>
      {props.children}
      <LoginWithFirebaseAsDialog />
    </useFirebaseMeState.Provider>);
});
