import { red, deepOrange as orange, yellow, green, teal as blueGreen, blue, indigo as bluePurple, purple, grey, blueGrey, } from '@mui/material/colors';
export const globalRed = {
    /** #ffebee */ r50: red[50],
    /** #ffcdd2 */ r100: red[100],
    /** #ef9a9a */ r200: red[200],
    /** #e57373 */ r300: red[300],
    /** #ef5350 */ r400: red[400],
    /** #f44336 */ r500: red[500],
    /** #e53935 */ r600: red[600],
    /** #d32f2f */ r700: red[700],
    /** #c62828 */ r800: red[800],
    /** #b71c1c */ r900: red[900],
    /** #d50000 */ rA700: red['A700'],
    /** #ff1744 */ rA400: red['A400'],
};
export const globalGreen = {
    /** #e8f5e9 */ g50: green[50],
    /** #c8e6c9 */ g100: green[100],
    /** #a5d6a7 */ g200: green[200],
    /** #81c784 */ g300: green[300],
    /** #66bb6a */ g400: green[400],
    /** #4caf50 */ g500: green[500],
    /** #43a047 */ g600: green[600],
    /** #388e3c */ g700: green[700],
    /** #2e7d32 */ g800: green[800],
    /** #1b5e20 */ g900: green[900],
    /** #69f0ae */ gA200: green['A200'],
    /** #00c853 */ gA700: green['A700'],
};
export const globalBlueGreen = {
    /** #e0f2f1 */ bg50: blueGreen[50],
    /** #b2dfdb */ bg100: blueGreen[100],
    /** #80cbc4 */ bg200: blueGreen[200],
    /** #4db6ac */ bg300: blueGreen[300],
    /** #26a69a */ bg400: blueGreen[400],
    /** #009688 */ bg500: blueGreen[500],
    /** #00897b */ bg600: blueGreen[600],
    /** #00796b */ bg700: blueGreen[700],
    /** #00695c */ bg800: blueGreen[800],
    /** #004d40 */ bg900: blueGreen[900],
};
export const globalBlue = {
    /** #e3f2fd */ b50: blue[50],
    /** #bbdefb */ b100: blue[100],
    /** #90caf9 */ b200: blue[200],
    /** #64b5f6 */ b300: blue[300],
    /** #42a5f5 */ b400: blue[400],
    /** #2196f3 */ b500: blue[500],
    /** #1e88e5 */ b600: blue[600],
    /** #1976d2 */ b700: blue[700],
    /** #1565c0 */ b800: blue[800],
    /** #0d47a1 */ b900: blue[900],
    /** #2979ff */ bA400: blue['A400'],
};
export const globalBlueGrey = {
    /** #eceff1 */ bg50: blueGrey[50],
    /** #cfd8dc */ bg100: blueGrey[100],
    /** #b0bec5 */ bg200: blueGrey[200],
    /** #90a4ae */ bg300: blueGrey[300],
    /** #78909c */ bg400: blueGrey[400],
    /** #607d8b */ bg500: blueGrey[500],
    /** #546e7a */ bg600: blueGrey[600],
    /** #455a64 */ bg700: blueGrey[700],
    /** #37474f */ bg800: blueGrey[800],
    /** #263238 */ bg900: blueGrey[900],
};
export const globalGrey = {
    /** #fafafa */ g50: grey[50],
    /** #f5f5f5 */ g100: grey[100],
    /** #eeeeee */ g200: grey[200],
    /** #e0e0e0 */ g300: grey[300],
    /** #bdbdbd */ g400: grey[400],
    /** #9e9e9e */ g500: grey[500],
    /** #757575 */ g600: grey[600],
    /** #616161 */ g700: grey[700],
    /** #424242 */ g800: grey[800],
    /** #212121 */ g900: grey[900],
};
export const purple300 = purple[300]; //#ba68c8
export const bluePurple300 = bluePurple[300]; //#7986cb
export const bluePurpleA400 = bluePurple['A400']; //#304ffe
export const globalOrange = {
    /** #fbe9e7 */ o50: orange[50],
    /** #ffccbc */ o100: orange[100],
    /** #ffab91 */ o200: orange[200],
    /** #ff8a65 */ o300: orange[300],
    /** #ff7043 */ o400: orange[400],
    /** #ff5722 */ o500: orange[500],
    /** #f4511e */ o600: orange[600],
    /** #e64a19 */ o700: orange[700],
    /** #d84315 */ o800: orange[800],
    /** #bf360c */ o900: orange[900],
};
export const globalYellow = {
    /** #fffde7 */ y50: yellow[50],
    /** #fff9c4 */ y100: yellow[100],
    /** #fff59d */ y200: yellow[200],
    /** #fff176 */ y300: yellow[300],
    /** #ffee58 */ y400: yellow[400],
    /** #ffeb3b */ y500: yellow[500],
    /** #fdd835 */ y600: yellow[600],
    /** #fbc02d */ y700: yellow[700],
    /** #f9a825 */ y800: yellow[800],
    /** #f57f17 */ y900: yellow[900],
};
export const FUTURESAI_THEME = '#324666';
export const WHITE = '#ffffff';
export const BLACK = '#000000';
