import { useState } from 'react';
import { useTimeout } from 'react-use';
import useMedia from '~/hooks/useMedia';
import { useAppLocalStorage } from '~/hooks/useAppLocalStorage';
import { createContainer } from '~/modules/unstated-next-utils/createContainer';
import dayAPI from '~/utils/dayAPI';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import tutorialCookieKeys from '~/modules/tutorial/tutorialCookieKeys';
const CHAPTER_KEYS = [
    'root',
    'quote',
    'opbs',
    'monitor',
    'screener',
    'os-futures-plan',
    'daily-chips',
    'volume-view',
    'amplitude-view',
];
const CHAPTER_TITLE = [
    '',
    '即時指標',
    '選擇權',
    '股市儀表',
    '市場監控',
    '交易筆記',
    '每日籌碼',
    '成交量資訊',
    '振幅資訊',
];
const CHAPTERS = [
    {
        title: '',
        chapterKey: 'root',
        icon: '',
        keywords: [],
    },
    {
        title: '即時指標',
        chapterKey: 'quote',
        icon: '/futuresai/tutorial/root/quote.png',
        keywords: ['quote'],
    },
    {
        title: '交易筆記',
        chapterKey: 'os-futures-plan',
        icon: '/futuresai/tutorial/root/os-futures-plans.png',
        keywords: ['os-futures-plans', 'mantra'],
    },
    {
        title: '選擇權',
        chapterKey: 'opbs',
        icon: '/futuresai/tutorial/root/opbs.png',
        keywords: ['opbs', 'option-price'],
    },
    {
        title: '每日籌碼',
        chapterKey: 'daily-chips',
        icon: '/futuresai/tutorial/root/daily-chips.png',
        keywords: ['daily-chips'],
    },
    {
        title: '股市儀表',
        chapterKey: 'screener',
        icon: '/futuresai/tutorial/root/screener.png',
        keywords: ['screener', 'screener-beta', 'ranking', 'ranking-realtime'],
    },
    {
        title: '成交量資訊',
        chapterKey: 'volume-view',
        icon: '/futuresai/tutorial/root/volume-view.png',
        keywords: ['volume-view'],
    },
    {
        title: '市場監控',
        chapterKey: 'monitor',
        icon: '/futuresai/tutorial/root/monitor.png',
        keywords: ['stock', 'os-futures'],
    },
    {
        title: '振幅資訊',
        chapterKey: 'amplitude-view',
        icon: '/futuresai/tutorial/root/amplitude-view.png',
        keywords: ['amplitude-view'],
    },
];
const useFuturesaiTutorial = () => {
    const meUserState = useMeStore(state => state.meUserState);
    /** 初始判斷使用 autoTutorDialogOpen */
    const [autoTutorDialogOpen, _setAutoTutorDialogOpen] = useState();
    /** 使用者互動使用 manualTutorDialogOpen */
    const [manualTutorDialogOpen, setManualTutorDialogOpen] = useState();
    const [mobileUserWelcomeDialogOpen, setMobileUserWelcomeDialogOpen] = useState();
    const [mobileStrangerDialogOpen, setMobileStrangerDialogOpen] = useState();
    const [fillFormOpen, setFillFormOpen] = useState();
    const { isPhone, isPad } = useMedia();
    const [isMainContent, setIsMainContent] = useState(false);
    const [isSimpleMode, setIsSimpleMode] = useState(true);
    const [currentChapter, setCurrentChapter] = useState();
    const hasLogin = meUserState !== null;
    const [isReady, cancel] = useTimeout(2000);
    const [autoTutorialValue, updateAutoTutorialValue] = useAppLocalStorage(tutorialCookieKeys.tutorialAutoTutorial);
    const tutorialProduct = meUserState?.subscriptions?.find(s => s.agentName === 'futuresai' && s.productName === 'web');
    if (isReady()) {
        /** 如何顯示？
         
         * 1. 不是手機版
         *
         * 2. 不是平板
         *
         * 3. 不是`預設關閉圖卡`
         *
         * 4. 無填寫資訊的對話框
         *
         * 5. 非正式付費會員
         *
         * 6. 註冊 30 天內
         */
        //INIT
        if (autoTutorDialogOpen === undefined) {
            const registationDays = dayAPI().diff(dayAPI(tutorialProduct?.startedAt)) / 60 / 60 / 24 / 1000;
            if (!isPhone &&
                !isPad &&
                autoTutorialValue !== 'no' &&
                !fillFormOpen &&
                tutorialProduct?.status !== 'premium' &&
                registationDays < 30) {
                _setAutoTutorDialogOpen(true);
            }
        }
        /**
         * 手機版「路人」提示INIT
         *
         * 1. 平板或手機
         *
         * 2. INIT狀態
         *
         * 3. 尚未登入
         *
         */
        if ((isPhone || isPad) && mobileStrangerDialogOpen === undefined && !hasLogin) {
            setMobileStrangerDialogOpen(true);
        }
        /**
         * 手機版「登入後」提示
         *
         * 1. 平板或手機
         *
         * 2. INIT狀態
         *
         * 3. 已經登入
         *
         * 4. 未開通會員
         *
         */
        if ((isPhone || isPad) &&
            mobileUserWelcomeDialogOpen === undefined &&
            hasLogin &&
            tutorialProduct?.status === null) {
            setMobileUserWelcomeDialogOpen(true);
        }
    }
    return {
        state: {
            autoTutorDialogOpen,
            manualTutorDialogOpen,
            mobileStrangerDialogOpen,
            mobileUserWelcomeDialogOpen,
            fillFormOpen,
            tutorialProduct,
            isMainContent,
            isSimpleMode,
            hasLogin,
            currentChapter,
            CHAPTERS,
            CHAPTER_KEYS,
        },
        acts: {
            setManualTutorDialogOpen,
            setMobileStrangerDialogOpen,
            setMobileUserWelcomeDialogOpen,
            setIsMainContent,
            setCurrentChapter,
            setIsSimpleMode,
            setFillFormOpen,
        },
    };
};
export const useFuturesaiTutorialState = createContainer(useFuturesaiTutorial);
