import { apirc } from '~/configs/apirc';
import { staticrcStore } from '~/configs/staticrcStore';
/**
 * # 計畫以 {@link staticStore} 替代
 *
 * 主要拿取合約 big_point_value 用於 indicator 裡，依據算績效
 *
 * @deprecated
 */
export const useStaticContractInfo = () => {
    if (!staticrcStore.contractInfo['TX-1']) {
        apirc.staticJson.fetchContractInfo().then(data => {
            staticrcStore.contractInfo = data;
        });
    }
};
