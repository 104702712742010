import { createStore } from '~/store/createStore';
/**
 * # 計畫以 {@link staticStore} 替代
 *
 * @deprecated
 */
export const useCommonSymbolListStore = createStore((set, get) => {
    return {
        tw50: [],
        otc50: [],
        osfuturesList: { overall: [], agriculture: [], metal: [], energy: [] },
        stockFuturesMapper: {},
        stockCategory: [],
    };
});
