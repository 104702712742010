import { defaultsDeep } from 'lodash';
import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
import dayAPI from '~/utils/dayAPI';
export class OptionsAPI extends Urlu {
    async fetchOpbs(params) {
        return await this.request.axios.get(`/v2/opbs`, {
            params: {
                q_gte: params?.q_gte,
                q_lte: params?.q_lte,
                m_gte: params?.m_gte,
                m_lte: params?.m_lte,
                from: String((params?.from?.toDate().getTime() ?? 0) / 1000),
                from__DEBUG__: params?.from?.format(),
                to: String((params?.to?.toDate().getTime() ?? 0) / 1000),
                to__DEBUG__: params?.to?.format(),
            },
        });
    }
    async fetchContracts(params) {
        params = defaultsDeep(params, {
            from: dayAPI(),
            to: dayAPI(),
        });
        return await this.request.axios.get(`/opcontracts`, {
            params: {
                from: String(params.from?.unix()),
                to: String(params.to?.unix()),
                from__DEBUG__: params.from?.format('YYYY-MM-DD HH:mm'),
                to__DEBUG__: params.to?.format('YYYY-MM-DD HH:mm'),
            },
        });
    }
    async fetchQuote(params) {
        params = defaultsDeep(params, {
            to: dayAPI(),
        });
        return await this.request.axios.get(`/options_price`, {
            params: {
                contract_month: params?.contract_month,
                to: params?.to?.unix(),
            },
        });
    }
}
