import { css } from '@emotion/react';
import { LinearProgress, CircularProgress } from '@mui/material';
import React, { memo } from 'react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
export const Loading = memo(function Loading(props) {
    return props.show === false ? null : (<CircularProgress size='small' className={props.className} css={css `
        ${flex.h.default};
        height: 40px;
        width: 40px;
        ${props.rootCSS}
      `}/>);
});
export const WaitingSocketConnectLoading = memo(function WaitingSocketConnectLoading() {
    return (<div css={css `
        height: 100%;
        min-height: 100vh;
        ${flex.v.allCenter};
      `}>
      <LinearProgress css={css `
          width: 24%;
        `}/>
      <p css={css `
          color: white;
        `}>
        等待連線
      </p>
    </div>);
});
