import { createStore } from '~/store/createStore';
/**
 * # 計畫以 {@link backendStore} 替代
 *
 * @deprecated
 */
export const useLatestTradeDateStore = createStore((set, get) => {
    return {
        date: '',
        intraday: '',
    };
});
