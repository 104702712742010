import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { createStore } from '~/store/createStore';
/**
 * # @deprecated
 *
 * ## 請嘗試用 {@link fr_me} 替代
 *
 * @deprecated
 */
export const useMeStore = createStore((set, get, api) => {
    return {
        init() {
            set(state => {
                state.agentProduct = fr_agents.agentProduct;
                state.agentName = fr_agents.agent;
                state.product = fr_agents.product;
                state.agentConfig = fr_agents.config;
            });
        },
        isValidTrialUser: false,
        agentProduct: fr_agents.agentProduct,
        agentName: fr_agents.agent,
        product: fr_agents.product,
        agentConfig: fr_agents.config,
        meFirebaseState: null,
        meUserState: null,
        meInfoState: null,
    };
});
