import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
export class StrategyProdAPI extends Urlu {
    /** 這邊專用的 jwt */
    get jwt() {
        return this._jwt;
    }
    allOverviews = this.createQuery('get', '/api/overviews');
    overview = this.createQuery('get', '/api/overviews/{strategyId}');
    strategy = this.createQuery('get', '/api/strategies/{id}');
    dailyProfit = this.createQuery('get', '/api/strategies/{id}/dailyProfit');
    transactionsStatements = this.createQuery('get', '/api/strategies/{strategyId}/transactions/statements');
    login = this.createQuery('post', '/api/user/login');
    me = this.createQuery('get', '/api/user');
    adminSearch = this.createQuery('get', '/api/admin/strategies/search');
    adminStrategy = this.createQuery('put', '/api/admin/strategies/{strategyId}');
    setJwt(jwt) {
        this._jwt = jwt;
    }
    _jwt = 'futuresai';
}
