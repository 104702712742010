import { ENV } from '~/configs/ENV';
import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import dayAPI from '~/utils/dayAPI';
import { __IS_CLIENT__ } from '~/utils/__IS_CLIENT__';
import { __TEST__ } from '~/utils/__TEST__';
export class ElkUrlu extends Urlu {
    uid = null;
    get request() {
        return super.request.removeJwt;
    }
    /**
     * # 改用
     *
     *      `elk.sendToUserWatchlist(message, payload)` etc...
     *
     * @deprecated 因為這個沒有區分出 index table As namespace，以及沒有限制起 payload 型別，恐會使 post 400 error
     */
    async sendMessage(message, payload) {
        if (!__IS_CLIENT__ || __TEST__)
            return null;
        debugAPI.elk.log(message, payload);
        const postbody = {
            message: message instanceof Error ? message.message : message,
            agent: useMeStore.getState().agentName,
            user: useMeStore.getState().meUserState || null,
            userAgent: navigator.userAgent,
            uid: this.uid,
            postedAt: dayAPI().format(),
            commit: {
                branch: ENV.BRANCH,
                hash: ENV.COMMITHASH,
                time: ENV.VERSION,
            },
            website: {
                url: location.href,
            },
            payload,
        };
        return await this.request.axios.post('/create/frontend_debug', postbody);
    }
    /** # 自選股 */
    async sendToUserWatchlist(message, payload) {
        return this.index('user-watchlist').send(message, payload);
    }
    /** 聲音播放失敗了 */
    async sendPlayingSoundError(error, data) {
        return await this.sendMessage(error, {
            ...data,
        });
    }
    /**
     * # 選擇 elk 上的 index table
     *
     *      ### 如果報了 400 error，留意在 index 的 payload 屬性有沒有參照一樣的前例型別
     *
     *      例如這次送 `payload: { result: '0050' }`
     *
     *      下次送 `payload: { result: [{ symbol: '0050 }] }`
     *
     *      可能就會產生 400 error
     */
    index(indexName) {
        return {
            send: async (message, payload) => {
                if (!__IS_CLIENT__ || __TEST__)
                    return null;
                debugAPI.elk.log(message, payload);
                const user = {
                    uid: this.uid,
                    ...useMeStore.getState().meUserState,
                };
                const postbody = {
                    message: message instanceof Error ? message.message : message,
                    agent: useMeStore.getState().agentName,
                    user,
                    userAgent: navigator.userAgent,
                    uid: useMeStore.getState().meUserState?.uid || this.uid,
                    postedAt: dayAPI().format(),
                    commit: {
                        branch: ENV.BRANCH,
                        hash: ENV.COMMITHASH,
                        time: ENV.VERSION,
                    },
                    website: {
                        url: location.href,
                    },
                    payload,
                };
                await this.request.axios.post(`/create/frontend-${indexName}`, postbody);
            },
        };
    }
}
