import { Fragment, memo } from 'react';
import { useEventsVisibleChange } from '~/modules/SDK/Events/useEventsVisibleChange';
import { useMeEnsureBackendContinue } from '~/modules/SDK/me/useMeEnsureBackendContinue';
import { useMeEnsureDevAgentContinue } from '~/modules/SDK/me/useMeEnsureDevAgentContinue';
import { useMeVerifyJwtContinue } from '~/modules/SDK/me/useMeVerifyJwtContinue';
const PostNextPageRenderComponent = memo(function PostNextPageRenderComponent() {
    useEventsVisibleChange();
    useMeEnsureDevAgentContinue();
    useMeVerifyJwtContinue();
    useMeEnsureBackendContinue();
    return <Fragment></Fragment>;
});
export default PostNextPageRenderComponent;
