import firebase from 'firebase/app';
import 'firebase/firestore';
if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: 'AIzaSyCn5dseBaKD4ESxCFdRvqNasYVVsDmtNs4',
        authDomain: 'futures-ai.firebaseapp.com',
        databaseURL: 'https://futures-ai.firebaseio.com',
        projectId: 'futures-ai',
        storageBucket: 'futures-ai.appspot.com',
        messagingSenderId: '463877906717',
    });
}
/**
 * - 在 import 此 function 之後，會自動附帶 firebase.initializeApp
 * - 你不需要自行「firebase.initializeApp({...})」
 */
export const getFirebaseApp = (name) => firebase.app(name);
/**
 * - 返回「firestore」而非「app」
 * - 在 import 此 function 之後，會自動附帶 firebase.initializeApp
 * - 你不需要自行「firebase.initializeApp({...})」
 *
 * @example
 *   // firebase.firestore
 *
 *   const firestore = getFirebaseStore()
 *
 *   firestore.FieldValue.arrayUnion()
 *
 * @example
 *   // firebase.firestore()
 *
 *   const firestore = getFirebaseStore()
 *
 *   firestore().collection('symbol_watch_list')
 */
export const getFirebaseStore = () => firebase.firestore;
