/* eslint-disable no-inner-declarations */
import produce, { enableMapSet } from 'immer';
import pipe from 'ramda/src/pipe';
import create from 'zustand';
import { expectError, expectType } from '~/utils/tsd';
import { __TEST__ } from '~/utils/__TEST__';
enableMapSet();
const immerMiddleware = (config) => (set, get, api) => config(fn => set(produce(fn)), get, api);
export const createStore = pipe(immerMiddleware, create);
/* istanbul ignore next */
if (__TEST__) {
    const useStore = createStore((set, get) => {
        return {
            value: 123,
        };
    });
    function TestComponent() {
        useStore(state => {
            expectType(state.value);
        });
        return <div></div>;
    }
    expectType(useStore.getState().value);
    /** @ts-expect-error error type check */
    expectError(useStore.getState().value);
    /** @ts-expect-error error type check */
    expectError(useStore.getState().nonExistProp);
}
