import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
export class SignalWallAPI extends Urlu {
    data = new Urlu.Query((params) => {
        return this.request.axios
            .get(this.baseUrl, {
            params: {
                ...params,
                from: params.from?.unix(),
                to: params.to?.unix(),
                from__DEBUG__: params.from?.format('YYYY-MM-DD HH:mm:ss'),
                to__DEBUG__: params.from?.format('YYYY-MM-DD HH:mm:ss'),
            },
        })
            .then(res => res.data);
    });
    /**
     * # @deprecated
     *
     * ## 改用 {@link SignalWallAPI.data}
     */
    async get(params) {
        const { data } = await this.request.axios.get(this.baseUrl, {
            params: {
                ...params,
                from: params.from?.unix(),
                to: params.to?.unix(),
                from__DEBUG__: params.from?.format('YYYY-MM-DD HH:mm:ss'),
                to__DEBUG__: params.from?.format('YYYY-MM-DD HH:mm:ss'),
            },
        });
        return data;
    }
}
