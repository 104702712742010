import { Urlu } from '~/modules/SDK/app/urlu/Urlu';
export class StrengthAPI extends Urlu {
    /** 大戶掛單、大戶力道 */
    async getDashboardData(
    /**
     * - `'dashboard_bs_indicator'`：大戶力道
     * - `'dashboard_trade_info'`：大戶掛單
     */
    target) {
        try {
            const data = await this.request.axios
                .get('/latest_value', {
                params: {
                    key: target,
                },
            })
                .then(res => res.data);
            return data;
        }
        catch (error) {
            console.error(`[ERROR/fallback] ${error?.message}`);
            const fallback = {
                value: 0,
                display_value: 0,
            };
            return fallback;
        }
    }
    /** 大戶掛單 v2 */
    async getDashboardTradeInfo() {
        try {
            const data = await this.request.axios
                .get('/latest_value', {
                params: { key: 'dashboard_trade_info' },
            })
                .then(res => res.data);
            return data;
        }
        catch (error) {
            return 0;
        }
    }
    /** 期天自制的vix波動率 */
    async getVixInfo() {
        {
            const data = await this.request.axios
                .get('/latest_value', {
                params: { key: 'atm_iv' },
            })
                .then(res => res.data);
            return data;
        }
    }
    /** 轉折資訊 */
    async getPivotData(
    /**
     * - E.g. `'TX_15K'`
     * - E.g. `'YM_60K'`
     * - E.g. `'TWN_15K'`
     */
    symbolStringWithTimeframe = 'TX_15K') {
        const data = await this.request.axios
            .get('/latest_value', {
            params: {
                key: `pivot_${symbolStringWithTimeframe.toUpperCase()}`,
            },
        })
            .then(res => res.data);
        return data;
    }
}
