import { useEffect } from 'react';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { apirc } from '~/configs/apirc';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
/**
 * # 持續根據 JWT 向後端確保 Firebase 會員或訪客資料有被儲存在後端資料庫之中
 *
 *     [YuCheng] 這邊的POST不會有UI，就算是admin介面也不會有註冊User的頁面
 *
 *     理想上User只有在第一次用他的FB/Google登入到我們的Firebase時才會使用到
 *
 *     像昨天開會有提到，可以先用/api/me測試有沒有存在DB,如果沒有就用POST存進DB
 *
 *     PUT我的使用情境是User主動想要更改顯示名字或是要綁定youtubeChannel時才會用到
 *
 *     [aqzhyi] 如果一直 POST 同個人會怎樣？不會有反應就是了對ㄇ
 *
 *     [YuCheng] 會回傳已存在，所以其實沒差
 *
 *     See the discussed https://futures-ai.slack.com/archives/C9NTJLGEB/p1607424970254000
 *
 *     [YuCheng] POST /me 需要多傳兩個query參數, 用來mapping firestore collection
 *
 * @example
 *   POST already exists user, wil get status code for 204 no content
 *
 *   see file `jwtRequiredFetcher.http`
 *
 * @see https://futures-ai.slack.com/archives/C9NTJLGEB/p1605769524435400
 */
export const useMeEnsureBackendContinue = () => {
    const firebaseState = useMeStore(state => state.meFirebaseState);
    const meUserState = useMeStore(state => state.meUserState);
    useEffect(() => {
        debugAPI.me.log(`${useMeEnsureBackendContinue.name}()`, { firebaseState, meUserState });
        if (firebaseState?.jwt && meUserState?.code) {
            return;
        }
        if (firebaseState?.jwt && !meUserState?.code) {
            apirc.me.api.signup.fetch().catch(() => {
                return apirc.me.api.signup.fetch();
            });
        }
    }, [firebaseState, firebaseState?.jwt, meUserState, meUserState?.code]);
};
