import isError from 'lodash/isError';
import { useCallback, useMemo } from 'react';
import { ENV } from '~/configs/ENV';
import { sendToSlack } from '~/modules/SDK/slack/sendToSlack';
import { __DEV__ } from '~/utils/__DEV__';
import { __IS_CLIENT__ } from '~/utils/__IS_CLIENT__';
import { __NL__ } from '~/utils/__NL__';
import { useMeStore } from '../me/useMeStore';
export const useSlack = () => {
    const me = useMeStore().meUserState;
    const postMessage = useCallback((text) => {
        const name = me?.name || '尚未登入';
        const userCode = me?.code || '尚未登入';
        const email = me?.email || '尚未登入';
        const route = globalThis.location.href;
        const browserAgent = __IS_CLIENT__ ? navigator.userAgent + __NL__ : '';
        const version = `＠${ENV.COMMITHASH}＠${ENV.VERSION}＠${ENV.BRANCH}`;
        const message = browserAgent +
            `（ 會員：${name} / ${userCode} / ${email} ）${__NL__}` +
            `（ 在：${route} ${version} ）${__NL__}` +
            `（ 描述：${text} ）${__NL__}` +
            `-------------------------------------------`;
        if (__DEV__) {
            console.error(message);
            return Promise.resolve(true);
        }
        return sendToSlack(message)
            .then(() => {
            return true;
        })
            .catch(error => {
            if (isError(error))
                console.error(error.message);
            return false;
        });
    }, [me?.name, me?.email, me?.code]);
    return useMemo(() => {
        return {
            postMessage,
        };
    }, [postMessage]);
};
