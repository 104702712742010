import dayjs from 'dayjs';
import sjcl from 'sjcl';
/**五個數字 */
export const getTodayTrialCode = () => {
    // get current date
    const formattedDate = dayjs().format('YYYYMMDD');
    // convert date to trial code
    const bitArray = sjcl.hash.sha256.hash(formattedDate);
    const digest_sha256 = sjcl.codec.hex.fromBits(bitArray);
    const trialCode = parseInt(digest_sha256.slice(0, 4), 16);
    const returnValue = ('00000' + trialCode).slice(-5); // insert zero
    return [digest_sha256, returnValue];
};
