import { css } from '@emotion/react';
import { createTheme as createMuiTheme } from '@mui/material/styles';
import { createTheme } from '~/modules/theme/createTheme';
const color = '#e7ebf0';
const backgroundColor = '#18181b';
/** @deprecated 這套設計出來後，大伙沒有共識，在隨後加零有一套新的 theme 模式，遵守她的，勿多頭馬車 */
export const darkTheme = createTheme({
    body: css `
    color: ${color};
    background-color: ${backgroundColor};
  `,
    components: {
        'MuiPaper-root': css `
      color: ${color};
      background-color: '#373b3d';
    `,
    },
});
export default createMuiTheme({
    palette: {
        mode: 'dark',
        background: { default: backgroundColor },
    },
});
