import { meCreateCheckHandler } from '~/modules/SDK/me/meCreateCheckHandler';
/** 同時確認有無序號登入 or 一般使用者有無該商品訂閱 */
/** 包含試用到期的使用者 */
export const meCheckHandlerTrialCodeAndProductWeb = meCreateCheckHandler(scope => {
    const isValidTrialUser = scope.isValidTrialUser;
    const subscriptions = scope.meUserState?.subscriptions?.find(item => {
        const agent = item.agentName;
        const product = item.productName;
        return agent === scope.agentName && product === 'web' && !item.isExpired;
    });
    return isValidTrialUser || !!subscriptions;
});
/**
 * 確認有無體驗序號
 *
 * 部分給「使用序號登入」的 UI，像是：
 *
 * 用在 LoginWithFirebaseAsDialog, UserAvatarAsDialogButton
 */
export const meCheckHandlerTrialCode = meCreateCheckHandler(scope => {
    const isValidTrialUser = scope.isValidTrialUser ?? false;
    return isValidTrialUser;
});
